"use client"

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { X } from "lucide-react"
import Link from "next/link"
import LoginForm from "@/components/common/auth/login-form"
import { useAuthModal } from "@/hooks/use-auth-modal"
import { useState } from "react"

export const LoginDialog = () => {
  const { isOpen, returnUrl, closeLoginModal, sourceUrl } = useAuthModal()
  const [showCloseBtn, setShowCloseBtn] = useState(true)

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) closeLoginModal()
      }}
    >
      <DialogContent
        onInteractOutside={(e) => e.preventDefault()}
        onEscapeKeyDown={(e) => e.preventDefault()}
        customClose={false}
      >
        {showCloseBtn ? (
          <Link href={sourceUrl}>
            <DialogClose className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary">
              <X className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </DialogClose>
          </Link>
        ) : null}
        <DialogHeader className="sr-only">
          <DialogTitle>Login Form</DialogTitle>
        </DialogHeader>
        <div
          className={`flex w-full flex-col items-center justify-center gap-y-4`}
        >
          <h1 className="h-fit text-3xl lg:text-4xl  font-orange tracking-normal">
            Login
          </h1>
          {/* <div className="flex items-center justify-center px-16 max-lg:px-0 font-gilroyMedium leading-[26px] tracking-[0px] text-zinc-600 mb-4">
            <p className="text-center text-sm">
              Towards Owning a Premium Pre-Owned Device!
            </p>
          </div> */}
        </div>
        <LoginForm
          returnUrl={returnUrl}
          onSuccess={closeLoginModal}
          setShowCloseBtn={setShowCloseBtn}
        />
      </DialogContent>
    </Dialog>
  )
}
