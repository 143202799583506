"use client"
import React, { useEffect, useState } from "react"
import { useRouter } from "next/navigation"

import Loader from "../button-loader"
import { Input } from "@/components/ui/input"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { signIn } from "next-auth/react"
import { sendOtp } from "@/server/api/loginActions"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { fetchUser, updateProfile } from "@/server/api/profileActions"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

export default function LoginForm({
  returnUrl,
  onSuccess,
  setShowCloseBtn,
}: {
  returnUrl: string
  onSuccess: () => void
  setShowCloseBtn: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const router = useRouter()
  const queryClient = useQueryClient()

  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(0)
  const [errorMessage, setErrorMessage] = useState("")
  const [otpSent, setOtpSent] = useState(false)
  const [timeLeft, setTimeLeft] = useState(0)
  const [otp, setOtp] = useState(new Array(4).fill(""))

  const { data, refetch } = useQuery({
    queryKey: ["fetch-user"],
    queryFn: fetchUser,
    enabled: otpSent,
    staleTime: 1000 * 60 * 5,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const [formDataOne, setFormDataOne] = useState({
    phone: "",
  })

  const [errorsOne, setErrorsOne] = useState({
    phone: "",
    otp: "",
  })

  const [formDataTwo, setFormDataTwo] = useState({
    fullName: "",
    email: "",
    gender: "",
  })

  const [errorsTwo, setErrorsTwo] = useState({
    fullName: "",
    email: "",
    gender: "",
  })

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1)
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [timeLeft])

  const handleChange = (element: any, index: number) => {
    if (isNaN(element.value)) return false
    const updatedOtp = [
      ...otp.map((d, idx) => (idx === index ? element.value : d)),
    ]
    setOtp(updatedOtp)

    // Focus next input if value is entered
    if (element.value && element.nextSibling) {
      element.nextSibling.focus()
    }
  }

  const handleBackspace = (event: any, index: number) => {
    if (event.key === "Backspace" && !otp[index]) {
      if (index > 0) {
        const newOtp = [...otp]
        newOtp[index - 1] = "" // Clear the previous box
        setOtp(newOtp)

        const prevSibling = event.target.previousSibling
        if (prevSibling) prevSibling.focus()
      }
    }
  }

  const validateFieldsOne = (): boolean => {
    const newErrors = {
      phone: /^[0-9]{10}$/.test(formDataOne.phone)
        ? ""
        : "Phone number must be 10 digits",
      otp: "",
    }

    setErrorsOne(newErrors)
    return Object.values(newErrors).every((error) => !error)
  }

  const validateFieldsTwo = (): boolean => {
    const newErrors = {
      fullName: formDataTwo.fullName ? "" : "Full Name is required",
      email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        formDataTwo.email
      )
        ? ""
        : "Email is required",
      gender: formDataTwo.gender ? "" : "Gender is required",
    }

    setErrorsTwo(newErrors)
    return Object.values(newErrors).every((error) => !error)
  }

  const handleSubmitTwo = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)
    setErrorMessage("")
    if (validateFieldsTwo()) {
      try {
        const payload = {
          name: formDataTwo?.fullName ?? "",
          email: formDataTwo?.email ?? "",
          gender: formDataTwo?.gender ?? "",
        }
        // console.log(payload)
        const res = await updateProfile({ payload })
        // console.log({ res }, "profile updated")
        await queryClient.invalidateQueries({
          queryKey: ["fetch-user"],
          exact: true,
        })
        onSuccess()
        router.refresh()
        router.push(returnUrl)
        setLoading(false)
      } catch (error) {
        setErrorMessage("An error occurred. Please try again later.")
        setLoading(false)
      } finally {
        setLoading(false)
      }
    }
    setLoading(false)
  }

  const handleOtpSend = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    if (validateFieldsOne()) {
      try {
        await sendOtp(formDataOne.phone)
        setOtpSent(true)
        setTimeLeft(30)
        setErrorsOne((prev) => ({ ...prev, phone: "" }))
      } catch (error) {
        setLoading(false)
      } finally {
        setLoading(false)
      }
    }
    setLoading(false)
  }

  const handleOtpSubmit = async (e: any) => {
    e.preventDefault()
    if (validateFieldsOne()) {
      if (otp.every((num) => num.length === 1)) {
        const otpPass = otp.join("")
        try {
          setLoading(true)
          const res = await signIn("credentials", {
            email: formDataOne.phone,
            password: otpPass,
            redirect: false,
          })
          if (res?.error) {
            setErrorsOne((prev) => ({ ...prev, otp: "Invalid otp" }))
          } else {
            await queryClient.invalidateQueries({
              queryKey: ["fetch-user"],
              exact: true,
            })

            const res = await refetch()
            if (!res?.data?.name) {
              setStep(1)
              setShowCloseBtn(false)
              return
            }

            onSuccess()
            router.refresh()
            router.push(returnUrl)
          }
          setLoading(false)
          return
        } catch (error) {
          setErrorsOne((prev) => ({
            ...prev,
            otp: "Invalid otp",
          }))
          setLoading(false)
        } finally {
          setLoading(false)
        }
      } else {
        setErrorsOne((prev) => ({
          ...prev,
          otp: "Invalid otp",
        }))
      }
    }
  }

  return (
    <>
      {step === 0 ? (
        <form className="flex h-fit w-full flex-col gap-2 sm:gap-6 lg:gap-3 p-4">
          <div className="group relative w-full mb-3">
            <label
              htmlFor="phone"
              className="absolute start-1 top-0 z-10 block -translate-y-1/2 bg-background px-2 text-sm font-gilroyMedium text-foreground"
            >
              Phone
            </label>
            <Input
              id="phone"
              type="tel"
              autoFocus
              className={cn(
                errorsOne.phone
                  ? "border-destructive/80 focus-visible:border-destructive/80 focus-visible:ring-destructive/0 h-12"
                  : "h-12 placeholder:text-gray-400 pl-4 border border-[#5F5F5F]",
                "focus:ring-[0.5px] ring-black rounded-md font-gilroyMedium"
              )}
              value={formDataOne.phone}
              onChange={(e) => {
                const inputValue = e.target.value
                const phoneRegex = /^[0-9]{0,10}$/

                if (!inputValue || phoneRegex.test(inputValue)) {
                  setFormDataOne((prev) => ({
                    ...prev,
                    phone: inputValue,
                  }))
                }
              }}
              maxLength={10}
              placeholder="Enter phone no."
            />
          </div>
          <div className="flex flex-col justify-center gap-y-3">
            <label
              htmlFor="otp"
              className="text-sm text-center font-gilroyMedium"
            >
              Enter OTP
            </label>
            <div className="flex gap-2 items-center justify-between lg:px-12">
              {otp.map((data, index) => (
                <input
                  key={index}
                  disabled={!otpSent}
                  className="size-12 text-xl bg-[#F6F6F6]  border-[#DDDDDD] text-center border rounded-lg font-gilroyMedium disabled:cursor-not-allowed"
                  type="text"
                  name="otp"
                  maxLength={1}
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onFocus={(e) => e.target.select()}
                  onKeyDown={(e) => handleBackspace(e, index)}
                />
              ))}
            </div>{" "}
          </div>

          <p
            className={cn(
              "text-center text-zinc-500 font-gilroyMedium -my-1",
              otpSent
                ? "opacity-100 pointer-events-auto"
                : "opacity-0 pointer-events-none"
            )}
          >
            {timeLeft > 0 ? (
              `Resend in ${timeLeft}s`
            ) : (
              <span
                className="text-blue-500 cursor-pointer"
                onClick={handleOtpSend}
              >
                Re-send OTP
              </span>
            )}
          </p>
          {otpSent ? (
            <Button
              onClick={handleOtpSubmit}
              disabled={loading}
              className="border rounded-[10px] font-gilroySemiBold  bg-black text-white py-4"
            >
              {loading ? <Loader /> : "Submit"}
            </Button>
          ) : (
            <Button
              onClick={handleOtpSend}
              disabled={loading}
              className="border rounded-[10px] font-gilroySemiBold  bg-black text-white py-4"
            >
              {loading ? <Loader /> : "Send Otp"}
            </Button>
          )}
          <p
            className={cn(
              errorsOne ? "opacity-100" : "opacity-0",
              "text-destructive/90 text-sm text-center font-gilroyMedium"
            )}
          >
            {errorsOne.phone && errorsOne.phone}
            {errorsOne.otp && errorsOne.otp}
          </p>
        </form>
      ) : null}

      {step === 1 ? (
        <form className="flex h-fit w-full flex-col gap-5 sm:gap-6 lg:gap-7 p-4">
          <div className="group relative w-full">
            <label
              htmlFor="fullname"
              className="absolute start-1 top-0 z-10 block -translate-y-1/2 bg-background px-2 text-sm font-gilroyMedium text-foreground"
            >
              Full Name
            </label>
            <Input
              id="fullname"
              name="fullname"
              type="text"
              className={cn(
                errorsTwo?.fullName
                  ? "border-destructive/80 focus-visible:border-destructive/80 focus-visible:ring-destructive/0 h-12"
                  : "h-12 placeholder:text-gray-400 pl-4  border border-[#5F5F5F]",
                "focus:ring-[0.5px] ring-black rounded-md font-gilroyMedium"
              )}
              value={formDataTwo?.fullName}
              onChange={(e) =>
                setFormDataTwo((prev) => ({
                  ...prev,
                  fullName: e.target.value,
                }))
              }
              placeholder="Enter your fullname"
            />
          </div>
          <div className="group relative w-full">
            <label
              htmlFor="email"
              className="absolute start-1 top-0 z-10 block -translate-y-1/2 bg-background px-2 text-sm font-gilroyMedium text-foreground"
            >
              Email
            </label>
            <Input
              id="email"
              type="email"
              className={cn(
                errorsTwo.email
                  ? "border-destructive/80 focus-visible:border-destructive/80 focus-visible:ring-destructive/0 h-12"
                  : "h-12 placeholder:text-gray-400 pl-4  border border-[#5F5F5F]",
                "focus:ring-[0.5px] ring-black rounded-md font-gilroyMedium"
              )}
              value={formDataTwo?.email}
              onChange={(e) =>
                setFormDataTwo((prev) => ({
                  ...prev,
                  email: e.target.value,
                }))
              }
              placeholder="Enter your email"
            />
          </div>

          <Select
            onValueChange={(e) =>
              setFormDataTwo((prev) => ({
                ...prev,
                gender: e,
              }))
            }
          >
            <SelectTrigger
              className={cn(
                errorsTwo.gender
                  ? "border border-destructive/80 focus-visible:border-destructive/80 focus-visible:ring-destructive/0 "
                  : "  px-5  border border-black ring-black ",
                "focus:ring-[0.5px] placeholder:text-gray-400  ",
                "w-full h-12 group relative focus:ring-offset-0 font-gilroyMedium flex justify-between rounded-md border"
              )}
            >
              <label
                htmlFor="email"
                className="absolute start-1 top-0 z-10 block -translate-y-1/2 bg-background px-2 text-sm font-gilroyMedium text-foreground"
              >
                Gender
              </label>
              <SelectValue
                placeholder={
                  <p className="font-gilroyMedium text-gray-400 placeholder:text-gray-400">
                    Select gender
                  </p>
                }
              />
            </SelectTrigger>
            <SelectContent className="font-gilroyMedium">
              <SelectItem
                value="Male"
                className="w-full py-3 px-4 justify-normal rounded-lg"
              >
                Male
              </SelectItem>
              <SelectItem
                value="Female"
                className="w-full py-3 px-4 justify-normal rounded-lg"
              >
                Female
              </SelectItem>
            </SelectContent>
          </Select>

          <Button
            onClick={handleSubmitTwo}
            className="border rounded-[10px] font-gilroySemibold bg-black text-white py-4"
          >
            {loading ? <Loader /> : "Submit"}
          </Button>
          {errorMessage && (
            <p className="text-sm text-red-600 font-gilroyMedium">
              {errorMessage}
            </p>
          )}
        </form>
      ) : null}
    </>
  )
}
