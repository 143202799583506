"use client"
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react"
import { usePathname } from "next/navigation"
import { LoginDialog } from "@/components/common/auth/login-dialog"

interface AuthModalContextProps {
  isOpen: boolean
  returnUrl: string
  sourceUrl: string
  openLoginModal: (targetUrl?: string, sourceUrl?: string) => void
  closeLoginModal: () => void
}

const AuthModalContext = createContext<AuthModalContextProps | undefined>(
  undefined
)

export const AuthModalProvider = ({ children }: { children: ReactNode }) => {
  const pathname = usePathname()
  const [isOpen, setIsOpen] = useState(false)
  const [returnUrl, setReturnUrl] = useState("")
  const [sourceUrl, setSourceUrl] = useState("")

  const openLoginModal = (tUrl?: string, sUrl?: string) => {
    if (!tUrl && !sUrl) {
      // setReturnUrl(url ?? "/")
      // setSourceUrl(sUrl ?? "/")
      setIsOpen(true)
    } else {
      setReturnUrl(tUrl ?? "/")
      setSourceUrl(sUrl ?? "/")
      setIsOpen(true)
    }
  }

  const closeLoginModal = () => {
    setIsOpen(false)
    setReturnUrl("")
    setSourceUrl("")
  }

  useEffect(() => {
    if (pathname === "/") closeLoginModal()
  }, [pathname])

  return (
    <AuthModalContext.Provider
      value={{ isOpen, returnUrl, openLoginModal, closeLoginModal, sourceUrl }}
    >
      {children}
      <LoginDialog />
    </AuthModalContext.Provider>
  )
}

export const useAuthModal = () => {
  const context = useContext(AuthModalContext)
  if (!context) {
    throw new Error("useAuthModal must be used within an AuthModalProvider")
  }
  return context
}

export default AuthModalProvider
