import { callAPI } from "../helper"

export type AddressDetails = {
  _id: string
  userId: string
  orgId?: string | null
  deleted_at?: string | null
  city?: string
  address?: string
  isPrimary: boolean
  createdAt?: string
  updatedAt?: string
  __v: number
}

export type User = {
  _id?: string
  name?: string
  first_name?: string
  last_name?: string
  gender?: string
  password?: string
  email?: string
  phone?: string
  role?: number
  designation?: string | null
  image?: string
  addressDetails?: AddressDetails[]
}

export type LoginResponse = {
  message: string
  token: string
  user: User[]
}

export async function sendOtp(phone: string) {
  try {
    const apiUrl = "https://gcp-api.edify.club/edifyweb-backend/v1/auth/send/otp"

    const response = await callAPI(apiUrl, "POST", { phone: phone })
    return response?.data
  } catch (error: any) {
    throw new Error(error?.response || "Failed to send otp")
  }
}

export async function verifyOtpAndLogin(phone: string, otp: string) {
  try {
    const apiUrl = "https://gcp-api.edify.club/edifyweb-backend/v1/auth/login/otp"

    const response = await callAPI(apiUrl, "POST", { phone: phone, otp: otp })
    return response?.data
  } catch (error: any) {
    throw new Error(error?.response || "Failed to verify otp")
  }
}
